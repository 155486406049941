import "../css/cms-page.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ResourceCard from "../components/ResourceCard";
import LoadingSpinner from "../components/LoadingSpinner";

try {
    require('dotenv').config();
} catch (e) {
    console.log('dotenv not found');
}

const gdd_url = process.env.REACT_APP_BACKEND + "/gdd";
const gad_url = process.env.REACT_APP_BACKEND + "/gad";
const gsd_url = process.env.REACT_APP_BACKEND + "/gsd";
const BACKEND = process.env.REACT_APP_BACKEND;

export default function CmsPage() {
    
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedCommittee, setSelectedCommittee] = useState(0);

    async function getGDD() {
        setIsLoading(true);
        // await axios.get(gdd_url)
        //     .then(res => {console.log(res.data); setData(res.data); setIsLoading(false)}); 
        fetch(gdd_url, {
            method: "GET",
            headers: {
                "ngrok-skip-browser-warning": "true",
                "Content-Type": "application/json",
            }
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data) {
                setData(data);
                setIsLoading(false);
            } else {
                console.log("Error");
            }
        })
    }

    async function getGAD() {
        setIsLoading(true);
        // await axios.get(gad_url)
        //     .then(res => {setData(res.data); setIsLoading(false)}); 
        fetch(gad_url, {
            method: "GET",
            headers: {
                "ngrok-skip-browser-warning": "true",
                "Content-Type": "application/json",
            }
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data) {
                setData(data);
                setIsLoading(false);
            } else {
                console.log("Error");
            }
        })
    }

    async function getGSD() {
        setIsLoading(true);
        // await axios.get(gsd_url)
        //     .then(res => {setData(res.data); setIsLoading(false)}); 
        fetch(gsd_url, {
            method: "GET",
            headers: {
                "ngrok-skip-browser-warning": "true",
                "Content-Type": "application/json",
            }
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data) {
                setData(data);
                setIsLoading(false);
            } else {
                console.log("Error");
            }
        })
    }

    useEffect(() => {
        getGDD();
    }, []);

    return (
        <>

            {
                isLoading ? (
                    <div className="body">
                        <LoadingSpinner type="1" />
                    </div>
                ) : (
                <div className="body" style={{justifyContent:"normal"}}>
                    <div className="committee-selection-box">
                        <div className={selectedCommittee === 0 ? "selected-committee-button-wrapper": "committee-button-wrapper"}
                            onClick={() => {
                                setSelectedCommittee(0);
                                getGDD();
                            }}
                        >
                            <p style={{color:"black", fontFamily:"sen", fontWeight:900}}>GDD</p>
                        </div>
                        <div className={selectedCommittee === 1 ? "selected-committee-button-wrapper": "committee-button-wrapper"}
                            onClick={() => {
                                setSelectedCommittee(1);
                                getGAD();
                            }}
                        >
                            <p style={{color:"black", fontFamily:"sen", fontWeight:900}}>GAD</p>
                        </div>
                        <div className={selectedCommittee === 2 ? "selected-committee-button-wrapper": "committee-button-wrapper"}
                            onClick={() => {
                                setSelectedCommittee(2);
                                getGSD();
                            }}
                        >
                            <p style={{color:"black", fontFamily:"sen", fontWeight:900}}>GSD</p>
                        </div>
                    </div><br></br>
                    {
                        [...data]
                            .sort((a, b) => parseInt(a.session) - parseInt(b.session))
                            .reverse()
                            .map((keys, idx, arr) => {
                                const isNewSession = idx === 0 || keys.session !== arr[idx - 1].session;
                                return (
                                    <div key={idx}>
                                        {isNewSession && <h2 className="session-title">Session {keys.session}</h2>}
                                        <ResourceCard resource_title={keys.title} resource_name={keys.name} resource_link={keys.aws_url} />
                                    </div>
                                );
                            })
                    }
                </div>
                )
            }
        </>
    )
}
